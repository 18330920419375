// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <a-select
    v-if="!isDisabled()"
    class="project-select"
    :loading="loading"
    v-model:value="projectSelected"
    :filterOption="filterProject"
    @change="changeProject"
    @focus="fetchData"
    showSearch>

    <template #suffixIcon>
      <a-tooltip placement="bottom">
        <template #title>
          <span>{{ $t('label.projects') }}</span>
        </template>
        <span class="custom-suffix-icon">
            <ProjectOutlined v-if="!loading" class="ant-select-suffix" />
            <LoadingOutlined v-else />
          </span>
      </a-tooltip>
    </template>

    <a-select-option
      v-for="(project, index) in projects"
      :key="index"
      :label="project.displaytext || project.name">
      <span>
        <resource-icon v-if="project.icon && project.icon.base64image" :image="project.icon.base64image" size="1x" style="margin-right: 5px"/>
        <project-outlined v-else style="margin-right: 5px" />
        {{ project.displaytext || project.name }}
      </span>
    </a-select-option>
  </a-select>
</template>

<script>
import store from '@/store'
import { api } from '@/api'
import _ from 'lodash'
import ResourceIcon from '@/components/view/ResourceIcon'
import TooltipLabel from '@/components/widgets/TooltipLabel.vue'

export default {
  name: 'SelectProjectView',
  components: {
    TooltipLabel,
    ResourceIcon
  },
  props: {
    resource: {
      type: Object,
      required: false
    },
    defaultprojectid: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      projects: [],
      loading: false
    }
  },
  created () {
    this.fetchData()
  },
  computed: {
    projectSelected () {
      let projectIndex = 0
      if (this.defaultprojectid) {
        projectIndex = this.projects.findIndex(project => project.id === this.defaultprojectid)
      } else if (this.resource?.defaultprojectid) {
        projectIndex = this.projects.findIndex(project => project.id === this.resource?.defaultprojectid)
      }
      return projectIndex
    },
    listingSelf () {
      if (this.resource?.id) {
        return this.$store.getters.userInfo.id === this.resource.id
      }
      return true
    }
  },
  methods: {
    fetchData () {
      if (this.isDisabled()) {
        return
      }
      const projects = []
      const params = {
        details: 'min',
        page: 1,
        pageSize: 500,
        showIcon: true
      }
      if (this.listingSelf || this.isAdmin(this.resource?.roletype)) {
        params.listall = true
      } else if (this.resource) {
        params.domainid = this.resource.domainid
        if (!this.isDomainAdmin(this.resource.roletype)) {
          if (this.resource.username) {
            params.username = this.resource.username
          } else {
            params.account = this.resource.name
          }
        }
      }

      const getNextPage = () => {
        this.loading = true
        api('listProjects', params).then(json => {
          if (json?.listprojectsresponse?.project) {
            projects.push(...json.listprojectsresponse.project)
          }
          if (projects.length < json.listprojectsresponse.count) {
            params.page++
            getNextPage()
          }
        }).finally(() => {
          this.projects = _.orderBy(projects, ['displaytext'], ['asc'])
          const topOption = this.resource ? this.$t('label.none') : this.$t('label.account.view')
          this.projects.unshift({ name: topOption })
          this.loading = false
        })
      }
      getNextPage()
    },
    isAdmin (roletype) {
      return ['Admin'].includes(roletype)
    },
    isDomainAdmin (roletype) {
      return ['DomainAdmin'].includes(roletype)
    },
    isDisabled () {
      return !Object.prototype.hasOwnProperty.call(store.getters.apis, 'listProjects')
    },
    changeProject (index) {
      this.$emit('project-selected', this.projects[index])
    },
    filterProject (input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }
}
</script>
